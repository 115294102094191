export default function ImgWithDashedBorder({
  src,
  alt,
  className,
}: {
  src: string;
  alt?: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <div
        className={`relative h-full w-full before:absolute before:top-0 before:size-full before:rounded-full before:border before:border-dashed before:border-Primary-Magic-Mint-Mm-600
              before:content-[''] ltr:before:left-2 rtl:before:right-2 `}
      >
        <img
          src={src}
          alt={alt}
          key={alt}
          className="block size-full  overflow-hidden rounded-full bg-gray-200 object-cover "
        />
      </div>
    </div>
  );
}
