import { useTranslation } from "react-i18next";
import { ReviewLabels } from "~/types/Review";
import { cn } from "~/utils/helper";

export default function ReviewLabel({
  label,
  className,
}: {
  label: string[];
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={cn("flex gap-2 max-md:gap-1", className)}>
      {label?.map((l) => (
        <p
          className={cn(
            "rounded-full bg-[#FB6481] px-3  py-1 text-sm font-semibold text-white max-md:px-1.5 max-md:py-0.5",
            {
              "bg-[#73AF00]": l === ReviewLabels.Design,
              "bg-[#FF7F50]": l === ReviewLabels.Production,
            },
          )}
        >
          {t(l)}
        </p>
      ))}
    </div>
  );
}
